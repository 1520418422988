import styles from "./ResponsiveGraphic.module.scss";

function ResponsiveGraphic({ children }) {
  return (
    <div className={styles.container}>
      <div className={styles.control}>{children}</div>
    </div>
  );
}

export default ResponsiveGraphic;
